:root {
    --white: #fff;
    --black: #000;
    --blue: #003552;
    --sky: #00a9e0;
    --sky-md: #009dd1;
    --sky-dk: #00779e;
    --red: #fe0015;
    --gray: #adafaf;
    --gray-lt: #f4f4f4;
    --gray-dk: #747676;
    --slate: #4d4f53;
    --transparent: transparent;
    --shadow: rgba(0,0,0,0.2);
    --serif: arno-pro,serif;
    --sans-serif: urw-din,sans-serif;
    --transition-duration: 300ms;
    --transition-easing: ease;
    --transition: var(--transition-duration) var(--transition-easing);
}
.search-zone {
    background-image: linear-gradient(var(--blue),var(--sky));
    color: white;
    padding: 6rem 2rem 4rem 2rem;
}
section.search-results {
    background-color: #d7d8d2;
}

.sui-layout-header {
    background-image: linear-gradient(var(--blue),var(--sky));
    color: white;
    padding: 6rem 2rem 4rem 2rem;
}

.sui-layout-header__inner{
    width:100%;
    padding-right:15px;
    padding-left: 15px;
    margin-right:auto;
    margin-left:auto;
}

.sui-search-box__text-input {
    box-shadow: none;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1rem;
}

.sui-search-box__text-input::placeholder {
    color: white;
}

.sui-search-box__wrapper {
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

.sui-search-box__text-input:focus {
    box-shadow: none;
    border: none;
}

.sui-search-box__text-input::before {
    content: "\f002";
    font: normal normal normal 14px/1 FontAwesome;
}

input.button.sui-search-box__submit {
    box-shadow: none;
    background-color: transparent;
    color: white;
    font-size: medium;
    box-shadow: 0px .25rem 0px 0px var(--sky);
    background: none;
    border-radius: 0;
    padding: 0.75rem 1.75rem 0.5rem;
    z-index: 2;
    border-bottom: none;
}

 input.button.sui-search-box__submit:hover {
    -webkit-transform: none;
    transform: none;
    box-shadow:  0px 0px 0px .25rem var(--sky);
}

.sui-layout-body {
    background: #d7d8d2;
}

.sui-result {
    padding: 0;
}

.sui-result__header {
    border-bottom: 1px solid rgba(0,0,0,.125);
    background-color: #eeefea;
    padding: .75rem 1.25rem;
    flex-direction: column;
}

.sui-result__url {
    width: 100%;
    margin-top: 10px;
}

.sui-result__url cite {
    font-weight: 500;
    color: #646C73 !important;
}

a.sui-result__title.sui-result__title-link {
    font-size: 1.25rem;
    color: var(--sky-dk);
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none;
    font-family: var(--sans-serif);
    width: 100%;
}

.sui-result__body {
    padding: 3rem 1.25rem;
    font-size: 1.25rem;
    font-family: var(--serif);
    line-height: 1.3;
}

.sui-result__key {
    font-family: monospace;
    color: #646C73 !important;
    font-weight: 600;
}

.sui-result__value {
    font-size: 1.25rem;
}

.sui-paging-info {
    font-size: 1.25rem;
    font-family: var(--serif);
}

ul.rc-pagination.sui-paging {
    color: var(--blue);
    font-family: var(--sans-serif);
    font-size: 1rem;
    letter-spacing: normal;
    line-height: 1.125;
    text-transform: none;
    font-weight: 700;
}
.sui-paging .rc-pagination-item a {
    color: var(--blue);
    background: white;
    padding: .75rem 1.5rem;
    border: solid 1px white;
    font-size: 1.25rem;
    line-height: 1.5;
}
.rc-pagination-item.rc-pagination-item-active a {
    background-color: var(--blue);
    border: solid 1px var(--blue);
    color: white;
    padding: .75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.sui-paging .rc-pagination-item a:hover {
    background-color: #e9ecef;
    border-color: #e9ecef;
    color: var(--blue);
}

.rc-pagination-jump-next:after, .rc-pagination-jump-prev:after {
    color: var(--blue);
}

.sui-paging .rc-pagination-jump-next:hover:after {
    color: var(--blue);
}

.rc-pagination-next a, .rc-pagination-prev a {
    color: var(--blue);
}

.sui-paging .rc-pagination-next:hover a {
    color: var(--blue);
}

.sui-result em {
    color: var(--blue);
}


.sui-search-bar-header input {
    max-width: 60%;
    color: var(--sky-md);
    font-size: .875rem;
}



.sui-search-bar-header input::placeholder {
    color: var(--sky-md);
}

.sui-search-bar-header input.button.sui-search-box__submit {
    background-color: white;
    color: var(--sky-md);
    box-shadow: none;
    font-size: .875rem;
}

@media (min-width: 64em) {
    .sui-search-bar-header input, .sui-search-bar-header input.button.sui-search-box__submit {
        font-size: 1.25rem;
    }

    .sui-search-box__text-input {
        font-size: xx-large;
    }

    input.button.sui-search-box__submit {
        font-size: x-large;
    }
}


@media (min-width: 576px){
    .sui-layout-header__inner {
        max-width: 540px;
    }
}

@media (min-width: 768px){
    .sui-layout-header__inner {
        max-width: 720px;
    }
}

@media (min-width: 992px){
    .sui-layout-header__inner {
        max-width: 960px;
    }
}

@media (min-width: 1200px){
    .sui-layout-header__inner {
        max-width: 1140px;
    }
}

